import { defineStore } from 'pinia';
import { readonly, Ref, ref, UnwrapRef } from 'vue';
import Meeting from '@/types/Meeting';

export const useMeetingStore = defineStore('meetingStore', () => {
    const meeting = ref<Meeting | null>(null);
    const meetingStarted = ref(false);

    const setMeeting = (meetingId: string, meetingRoomName: string) => {
        meeting.value = {
            id: meetingId,
            room_name: meetingRoomName,
        };
    };

    const openMeeting = () => {
        meetingStarted.value = true;
    };

    const closeMeeting = () => {
        meeting.value = null;
        meetingStarted.value = false;
    };

    return {
        meeting: readonly(meeting) as Ref<UnwrapRef<Meeting | null>>,
        meetingStarted: readonly(meetingStarted) as Ref<UnwrapRef<boolean>>,
        setMeeting,
        openMeeting,
        closeMeeting,
    };
});
