import { createI18n } from 'vue-i18n';
import { LOCALE } from '@/Enums/Locale';
import fr from './Locales/fr.json';
import en from './Locales/en.json';

// Uncomment it if you need to have other language than french
// const getCookieSelectedLang = (): string | null => {
//     let cookieLocale: string = LOCALE.FR;
//
//     try {
//         cookieLocale =
//             document.cookie && document.cookie.split('locale=')[1] && document.cookie.split('locale=')[1].split(';')[0];
//     } catch (e) {
//         console.log(e);
//     }
//     if (cookieLocale) {
//         return cookieLocale;
//     }
//     return null;
// };

// const getBrowserDefaultLang = (): string => {
//     if (navigator.languages !== undefined) {
//         return navigator.languages[0].slice(0, 2);
//     }
//     return navigator.language.slice(0, 2);
// };

// const getDefaultLang = (): string => {
//     const selectedLang = getCookieSelectedLang();
//     if (selectedLang) {
//         return selectedLang;
//     }
//     const browserDefaultLang: string = getBrowserDefaultLang();
//     return browserDefaultLang === LOCALE.FR || browserDefaultLang === LOCALE.EN ? browserDefaultLang : LOCALE.EN;
// };

const i18n = createI18n<false>({
    legacy: false,
    locale: LOCALE.EN, // For the moment keep it only in French. Otherwise, use getDefaultLang()
    fallbackLocale: LOCALE.EN,
    globalInjection: true,
    messages: {
        fr,
        en,
    },
});

document.cookie = `locale=${LOCALE.EN}; path=/`;
export default i18n;
