<script lang="ts" setup>
import TransitionFade from '@/Transitions/TransitionFade.vue';

const props = defineProps<{
    isLoading?: boolean;
}>();
</script>

<template>
    <TransitionFade>
        <div v-if="props.isLoading" id="atom-loader">
            <span class="loader"></span>
        </div>
    </TransitionFade>
</template>

<style scoped lang="scss">
#atom-loader {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    position: absolute;
    top: 0;
    left: 0;
}
</style>
