import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useOnline } from '@vueuse/core';
import { presentToast } from '@/utils/presentToast';
import { AxiosError } from 'axios';
import { getToken, onMessage, MessagePayload, deleteToken } from 'firebase/messaging';
import { messaging } from '@/firebase';
import { Device } from '@capacitor/device';
import { sendTokenForUser } from '@/api/notification';
import { useAlertStore } from '@/store/useAlertStore';
import i18n from '@/i18n';
import { useMeetingStore } from '@/store/useMeetingStore';

export const useGlobalStore = defineStore('global', () => {
    const isOnline = useOnline();
    const isLoading = ref(false);
    const error = ref<Error | null>(null);
    const isReady = ref(false);
    const alertStore = useAlertStore();
    const meetingStore = useMeetingStore();
    const { t } = i18n.global;

    const apiCall = async (fn: () => Promise<void>) => {
        if (!isOnline.value) {
            error.value = new Error('No internet connection');
            return;
        }
        isReady.value = true;
        isLoading.value = true;
        try {
            await fn();
        } catch (e: unknown) {
            if (e instanceof AxiosError) {
                error.value = e;
                if (!e.response?.data?.message) return;
                await presentToast(e.response?.data?.message, 'danger');
            }
        } finally {
            isLoading.value = false;
        }
    };

    const setFirebaseToken = async () => {
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
            await presentToast(t('permissionsNotGranted'), 'danger');
            return;
        }

        getToken(messaging, { vapidKey: import.meta.env.VITE_FIREBASE_PUBLIC_VAPID_KEY })
            .then(async (currentToken) => {
                const deviceID = await Device.getId();
                if (currentToken && deviceID.identifier) {
                    await apiCall(async () => {
                        await sendTokenForUser(currentToken, deviceID.identifier);
                    });
                }
            })
            .catch((err) => {
                console.error('An error occurred while retrieving token. ', err);
                deleteToken(messaging);
            });
    };

    onMessage(messaging, async (payload: MessagePayload) => {
        const alertId = payload.data?.alert_id as number | undefined;
        const meetingRoomName = payload.data?.meeting_room_name as string | undefined;
        const meetingId = payload.data?.meeting_id as string | undefined;

        if (alertId) {
            await alertStore.getAlert(alertId);
        }

        if (meetingRoomName && meetingId) {
            meetingStore.setMeeting(meetingId, meetingRoomName);
        }

        const title = payload.notification?.title;
        const body = payload.notification?.body;

        if (!title || !body) return;

        await presentToast(body, 'primary', title);
    });

    return {
        apiCall,
        isLoading,
        error,
        isReady,
        isOnline,
        setFirebaseToken,
    };
});
