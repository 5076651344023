import { defineStore } from 'pinia';
import { useGlobalStore } from '@/store/useGlobalStore';
import { ref } from 'vue';

import { Alert } from '@/types/Alert';
import { fetchAlert, postAction } from '@/api/alert';

export const useAlertStore = defineStore(
    'alert',
    () => {
        const alert = ref<Alert | null>(null);

        const getAlert = async (id: number) => {
            const { apiCall } = useGlobalStore();
            await apiCall(async () => {
                const { data } = await fetchAlert(id);
                setAlert(data as Alert);
            });
        };

        const setAlert = (newAlert: Alert | null) => {
            if (newAlert && newAlert.status === 'pending') {
                alert.value = newAlert;
            } else {
                alert.value = null;
            }
        };

        const sendAction = async ({ alertId, actionConfigurationId, comment }) => {
            const { apiCall } = useGlobalStore();
            await apiCall(async () => {
                await postAction(alertId, actionConfigurationId, comment);
            });
        };

        return {
            alert,
            getAlert,
            setAlert,
            sendAction,
        };
    },
    { persist: true }
);
