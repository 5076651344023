import { UserLogin, UserResetPassword } from '@/types/User';
import axios from './index';

export async function postLogin(credentials: UserLogin) {
    return await axios.post('login', credentials);
}

export async function fetchUser() {
    return await axios.get('api/user');
}

export async function postLogout() {
    return await axios.post('logout');
}

export async function postForgotPassword(email: string) {
    return await axios.post('forgot-password', { email });
}

export async function postResetPassword(data: UserResetPassword) {
    return await axios.post('reset-password', data);
}
