import MapboxGL from 'mapbox-gl';

MapboxGL.accessToken = import.meta.env.VITE_MAPBOX_KEY;
MapboxGL.setRTLTextPlugin(
    'https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-rtl-text/v0.2.3/mapbox-gl-rtl-text.js',
    (error) => {
        console.log(error);
    }
);

export default MapboxGL;
