import { readonly, Ref, ref, UnwrapRef, watch } from 'vue';
import { Alert } from '@/types/Alert';
import useAlerts from '@/Composables/useAlerts';

const { sharedAlerts } = useAlerts();

const selectedAlert = ref<Alert | null>(null);

export default function useSelectedAlert() {
    const setSelectedAlert = (alert: Alert) => {
        selectedAlert.value = alert;
    };

    const clearSelectedAlert = () => {
        selectedAlert.value = null;
    };

    return {
        selectedAlert: readonly(selectedAlert) as Ref<UnwrapRef<Alert>>,
        setSelectedAlert,
        clearSelectedAlert,
    };
}

watch(
    sharedAlerts,
    () => {
        if (selectedAlert.value) {
            const selectedAlertInSharedAlerts = sharedAlerts.value.find(
                (sharedAlert) => sharedAlert.id === selectedAlert.value?.id
            ) as Alert;
            useSelectedAlert().setSelectedAlert(selectedAlertInSharedAlerts ?? null);
        }
        const selectedAlertHasBeenRemove =
            selectedAlert.value &&
            !sharedAlerts.value.find((sharedAlert) => sharedAlert.id === selectedAlert.value?.id);

        if (selectedAlertHasBeenRemove) {
            useSelectedAlert().clearSelectedAlert();
        }
    },
    { deep: true }
);
