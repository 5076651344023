<script setup lang="ts">
import { useRegisterSW } from 'virtual:pwa-register/vue';
import AtomButton from '@/components/Atoms/Buttons/AtomButton.vue';

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW();

async function close() {
    offlineReady.value = false;
    needRefresh.value = false;
}
</script>

<template>
    <div
        v-if="offlineReady || needRefresh"
        class="fixed top-4 right-4 left-4 md:left-auto bg-white p-10 rounded shadow z-50 text-black"
        role="alert"
    >
        <div class="message mb-4">
            <span v-if="offlineReady"> {{ $t('offline_ready') }} </span>
            <span v-else> {{ $t('new_content') }} </span>
        </div>
        <AtomButton
            class="text-white bg-primary border shadow px-6 relative after:content-[''] after:absolute after:-top-1 after:-left-1 after:w-3 after:h-3 after:rounded-full mr-4"
            @click="updateServiceWorker(true)"
        >
            {{ $t('reload') }}
        </AtomButton>
        <AtomButton
            data-testid="closeReloadModalButton"
            class="text-white bg-primary border shadow px-6 relative after:content-[''] after:absolute after:-top-1 after:-left-1 after:w-3 after:h-3 after:rounded-full"
            @click="close"
        >
            {{ $t('close') }}
        </AtomButton>
    </div>
</template>
