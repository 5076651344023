import { readonly, Ref, ref, UnwrapRef } from 'vue';
import { Alert } from '@/types/Alert';

const sharedAlerts = ref<Alert[]>([]);

export default function useAlerts() {
    const addAlert = (newAlert: Alert) => {
        const audio = new Audio(newAlert.alert_setting.sound_path);

        // if alert already exists, replace it with the new one otherwise add it to the list
        const existingAlertIndex = sharedAlerts.value.findIndex((alert: Alert) => alert.id === newAlert.id);
        if (existingAlertIndex !== -1) {
            sharedAlerts.value[existingAlertIndex] = newAlert;
        } else {
            sharedAlerts.value.push(newAlert);
            audio.play();
        }
    };
    const setAlerts = (newAlerts: Alert[]) => {
        // remove alerts that are in double for the history
        const alertsNotInDouble: Alert[] = [];
        newAlerts.forEach((newAlert: Alert) => {
            const existingAlertIndex = alertsNotInDouble.findIndex((alert: Alert) => alert.id === newAlert.id);
            if (existingAlertIndex === -1) {
                alertsNotInDouble.push(newAlert);
            }
        });

        sharedAlerts.value = alertsNotInDouble;
    };

    const clearAlerts = () => {
        sharedAlerts.value = [];
    };

    return {
        sharedAlerts: readonly(sharedAlerts) as Ref<UnwrapRef<Alert[]>>,
        addAlert,
        setAlerts,
        clearAlerts,
    };
}
