import { readonly, Ref, ref, UnwrapRef, watch } from 'vue';
import { ActionConfiguration } from '@/types/ActionConfiguration';
import useSelectedAlert from '@/Composables/useSelectedAlert';

const { selectedAlert } = useSelectedAlert();

const selectedActionConfiguration = ref<ActionConfiguration | null>(null);

export default function useAction() {
    const setSelectedActionConfiguration = (actionConfiguration: ActionConfiguration) => {
        selectedActionConfiguration.value = actionConfiguration;
    };

    const clearSelectedActionConfiguration = () => {
        selectedActionConfiguration.value = null;
    };

    return {
        setSelectedActionConfiguration,
        clearSelectedActionConfiguration,
        selectedActionConfiguration: readonly(selectedActionConfiguration) as Ref<UnwrapRef<ActionConfiguration>>,
    };
}

watch(selectedAlert, () => {
    useAction().clearSelectedActionConfiguration();
});
