import { toastController } from '@ionic/vue';

type ToastType = 'success' | 'danger' | 'warning' | 'primary' | 'secondary';

export const presentToast = async (message: string, type: ToastType | undefined, header?: string) => {
    const toast = await toastController.create({
        header: header,
        message: message,
        duration: 2500,
        position: 'top',
        color: type,
    });

    await toast.present();
};
