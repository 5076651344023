import axios, { AxiosInstance } from 'axios';
import { getCookie } from '@/utils/cookies';
import { useUserStore } from '@/store/useUserStore';

const setCsrfToken = (token: string) => {
    axios.defaults.headers.common['X-XSRF-TOKEN'] = token;
};

const getCsrfToken = async () => {
    await axios.get('/sanctum/csrf-cookie');
    setCsrfToken((getCookie('XSRF-TOKEN') as string) || '');
};

axios.defaults.baseURL = import.meta.env.VITE_API_URL as string;
axios.defaults.withXSRFToken = true;
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Accept'] = 'application/json';
setCsrfToken((getCookie('XSRF-TOKEN') as string) || '');

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response?.status === 419) {
            await getCsrfToken();
            return axios.request(error.config);
        } else if (error.response?.status === 401) {
            const userStore = useUserStore();
            userStore.removeAuthentificationData();
        }
        return Promise.reject(error);
    }
);

export default axios as AxiosInstance;
