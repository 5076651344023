import axios from './index';

export async function fetchAlert(id: number) {
    return await axios.get(`api/alerts/${id}`);
}

export async function postAction(alertId: number, actionConfigurationId: number, comment: string | null) {
    return await axios.post(`api/alerts/${alertId}/action-configurations/${actionConfigurationId}/actions/create`, {
        comment,
    });
}
