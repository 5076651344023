<script lang="ts" setup>
import { computed } from 'vue';
import AtomLoader from '@/components/Atoms/Loaders/AtomLoader.vue';
import AtomCircle from '@/components/Atoms/AtomCircle.vue';

const props = defineProps<{
    icon?: string;
    isLoading?: boolean;
    circle?: boolean;
    disabled?: boolean;
}>();

const isDisabled = computed(() => props.isLoading || props.disabled);
</script>

<template>
    <button
        v-ripple
        type="button"
        :class="props.circle ? 'rounded-full' : 'rounded'"
        class="group rounded py-2 px-3 relative"
        :disabled="isDisabled"
        data-testid="button"
    >
        <AtomLoader :is-loading="props.isLoading" class="rounded" />
        <AtomCircle v-if="props.icon" class="absolute -top-5 -left-4 z-10">
            <span class="material-symbols-outlined text-primary">{{ props.icon }}</span>
        </AtomCircle>
        <slot />
        <span
            v-if="!isDisabled"
            :class="props.circle ? 'rounded-full' : 'rounded'"
            class="group-hover:bg-light w-full h-full absolute top-0 right-0 block focus:bg-light transition duration-150 ease-in-out"
        ></span>
    </button>
</template>
